.pretherapy-tplelt {
  &__container {
    flex-direction: column;
    width: 100%;
    max-width: 768px;

    @media (max-width: $screen-md) {
      display: block;
    }
  }

  &__btns {
    max-width: 540px;

    @media (max-width: $screen-md) {
      max-width: 100%;
    }
  }
}
