.copy_answers {
  .ant {
    &-form-item {
      flex-direction: column;

      label {
        width: 100%;
      }
    }

    &-modal {
      &-title {
        font-size: $fontSize-sm;
        line-height: 21px;

        color: #2c2c2c;
      }

      &-footer {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
