.placeholder {
  margin-left: 40px;
  font-size: $fontSize-sm;
  line-height: 25px;
}

.list {
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
  padding-left: 0 !important;
  &__header {
    height: 50px;
    background: #eff2f4;
    border-radius: 4px;

    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__areas {
      margin-left: 14px;
      font-size: $fontSize-sm;
      line-height: 21px;
      color: $color-darkBlue;
    }

    &__types {
      color: $color-grayLighten;
      margin-right: 34px;
      width: 113px;
      display: flex;
      justify-content: space-between;
    }
  }

  &__item {
    padding: 14px 17px;
    padding-right: 10px;

    border-bottom: 1px solid #dfe0eb;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &__badges {
      width: 160px;
      display: flex;
      justify-content: space-between;
    }
  }
}
