.pain-questions-page {
  &__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1024px;

    .ant-form-item {
      flex-direction: column;

      &-required {
        width: 100%;

        &::before {
          margin-right: 8px !important;
        }
      }

      &-control {
        margin-left: 12px;
      }
    }
  }

  &__table {
    flex-grow: 1;
    max-width: 540px;
    padding-right: $spacer-8;

    @media (max-width: $screen-md) {
      max-width: 100%;
      padding-right: 0px;
    }
  }

  &__image {
    width: 100%;
    max-width: 420px;
    margin: 0 0 24px;

    &_mobile {
      display: none;

      @media (max-width: $screen-md) {
        display: block;
        justify-self: center;
      }
    }

    &_desktop {
      display: block;

      @media (max-width: $screen-md) {
        display: none;
      }
    }
  }

  &__btns {
    max-width: 540px;

    @media (max-width: $screen-md) {
      max-width: 100%;
    }
  }
}
