.sym-med-list-page {
  &__treatment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;

    .ant-row {
      width: 100%;
      margin-bottom: 0;
    }

    .ant-btn {
      width: 26px;
      height: 30px;
      background: transparent;
      padding: 4px;
      border: none;
    }
  }

  &__select {
    margin-bottom: 24px !important;
  }

  &__remove-btn {
    width: 100%;
    height: 100%;
    background-image: url(../../images/svg/remove-btn.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }

  &__add-btn {
    margin-top: 20px;
  }
}
