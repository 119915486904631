h2 {
  color: $color-primary;
  margin-bottom: 26px;

  font-weight: 500;
  font-size: $fontSize-lg;
  line-height: 28px;
  text-transform: uppercase;
  margin-top: 45px;

  @media screen and (max-width: 987px) {
    & {
      text-align: center;
      margin-top: 0px;
    }
  }
}

.body_areas__checkbox_group {
  .ant-checkbox-wrapper {
    margin-bottom: 14px;
    margin-left: 0px !important;

    border: 2px solid #dfe9fc;
    border-radius: 5px;
    align-items: center;
    height: 61px;
    width: 100%;

    .ant-checkbox {
      display: none;
    }

    span {
      display: flex;
      align-items: center;
      color: #2c2c2c;
      font-weight: 500;
      font-size: $fontSize-xs;
      line-height: 16px;

      svg {
        margin-right: 10px;
      }
    }

    &-checked {
      background: #dfe9fc;
      border: 2px solid $color-primary;
      box-shadow: 0px 4px 4px #dfe9fc;
    }

    @media screen and (max-width: 1276px) {
      & {
        width: 100% !important;
      }
    }
  }
}
