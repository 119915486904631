.form {
  &__title {
    font-family: $fontFamily-Roboto;
    font-size: $fontSize-2xlg;
    line-height: 35px;
    margin-bottom: 30px;
  }

  &__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: $fontSize-lg;
    line-height: 28px;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: $fontSize-sm;
    line-height: 21px;
    color: rgba(44, 44, 44, 0.6);
  }

  &__radio {
    margin-top: 9px;
    margin-left: 11px;

    &--block {
      margin-top: 10px;
      label {
        display: block;
        margin-top: 10px;
      }
    }
  }

  &__checkbox {
    width: 100%;
    margin-top: 9px;
    margin-left: 11px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 15px;

    label {
      width: fit-content;
    }

    @media (max-width: $screen-sm) {
      grid-template-columns: 100%;
    }
  }

  &__select__group {
    &__header {
      font-size: $fontSize-xxs;
      line-height: 16px;
      color: #0847be;
    }

    &__header::before {
      margin-right: 4px;
      color: #ff4d4f;
      content: '*';
    }

    &__item {
      display: block;
      margin-top: 12px;
      margin-bottom: 18px;

      .ant-form-item-label {
        margin-right: 20px;
        label {
          color: black;
        }

        label::before {
          visibility: hidden;
          position: absolute;
        }
      }
      .ant-form-item-control {
        display: inline-block;
        width: 150px;
        height: 38px;
      }

      .ant-form-item-explain {
        position: absolute;
        width: 200px;
        left: 170px;
        bottom: 0px;
      }
    }
  }

  &__btns,
  &__btns_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  &__btns {
    width: 100%;
    margin-top: 20px;
    &_container {
      width: 380px;
    }

    &--wide {
      .form__btns_container {
        width: 100%;
      }
    }
  }

  &__finish {
    justify-content: flex-end;
  }

  &__btn {
    border: 2px solid #dfe9fc;
    box-shadow: 0px 2px 4px rgba(38, 50, 56, 0.08), 0px 4px 6px rgba(38, 50, 56, 0.08);
  }

  &__add_btns {
    max-width: 200px;
    margin: 20px 0 20px 10px;
  }

  &__add_btn {
    margin: 0 0 20px 10px;
  }

  &__status {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 4px 12px;
    background-color: $color-primary;
    color: white;

    font-size: $fontSize-xs;

    &--error {
      background-color: $color-red;
    }
  }

  &__comments {
    &__control_buttons {
      justify-content: flex-end;
    }
  }

  &__subhead {
    font-size: $fontSize-lg;
    line-height: 28px;
    color: #2c2c2c;
  }

  &__tip {
    font-size: $fontSize-sm;
    line-height: 21px;
    color: rgba(44, 44, 44, 0.6);
  }

  &__diary {
    &__select__group {
      margin-bottom: 0;

      &__item {
        display: block;
        margin-bottom: 0;
        margin-top: 12px;

        .ant-form-item-label {
          label {
            color: black;
          }

          label::before {
            visibility: hidden;
            position: absolute;
          }
        }
        .ant-form-item-control {
          width: 100%;
          height: 38px;
        }
      }
    }

    &__checkbox {
      label {
        line-height: 14px;
      }
    }
  }

  &__select {
    &__pain_relief {
      width: 140px !important;
    }
  }
}

.promis {
  &__finished_text {
    font-size: $fontSize-sm;
    line-height: 140%;
    color: rgba(44, 44, 44, 0.6);
  }

  &__label {
    line-height: 25px;
    font-size: 20px;
  }
}

.tooltip-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tooltip {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  background-image: url(../../images/svg/tooltip.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  flex-shrink: 0;
}
