.pretherapy-text-page {
  &__container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  }

  &__title {
    text-align: center;
  }

  &__btn {
    margin-top: 56px;
    padding: 0 29px;
    width: 200px;
  }
}
