.layout-mobile {
  max-width: 768px;
  margin: 0 auto;
  padding: 34px 32px;

  &__title {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: $fontSize-2xlg;
    line-height: 35px;
    letter-spacing: 0.5px;
  }

  &__checkbox {
    margin: 0 0 26px;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    & span {
      font-style: normal;
      font-weight: normal;
      font-size: $fontSize-xxs;
      line-height: 16px;
    }
  }

  .ant-select-selection-item {
    color: #808080;
    opacity: 0.7;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: $fontSize-xxs;
    line-height: 16px;
    color: rgba(44, 44, 44, 0.6);
  }

  &__container {
    padding-top: 24px;
  }

  &__subtitle {
    margin-bottom: 29px;
    font-style: normal;
    font-weight: normal;
    font-size: $fontSize-lg;
    line-height: 28px;
  }

  &__btn {
    width: 100%;

    &_remove,
    &_add {
      position: relative;
      padding-left: 34px;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: 5px;
        width: 14px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }

    &_remove::before {
      background-image: url(../../images/svg/remove-btn.svg);
      filter: invert(14%) sepia(52%) saturate(5897%) hue-rotate(219deg) brightness(106%) contrast(94%);
    }

    &_add::before {
      background-image: url(../../images/svg/add-btn.svg);
    }
  }

  &__add_container {
    margin: 20px 0;
  }

  &__btns {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  &__popup {
    margin-bottom: 20px;
    .popup {
      margin-left: 0;
      width: 100%;
    }
  }

  &__inputs_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;

    .ant-form-item {
      width: 100%;
    }
  }
}
