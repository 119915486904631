.canvas__control__button {
  color: white;
  background: linear-gradient(270deg, #1a73e9 0%, $color-primary-light 100%);
  background: #1a73e9;
  box-shadow: 0px 2px 4px rgba(38, 50, 56, 0.08), 0px 4px 6px rgba(38, 50, 56, 0.08);
  border-radius: 6px;
  font-size: $fontSize-sm;
  line-height: 21px;
  height: 40px;
  text-transform: uppercase;

  &--transparent {
    color: #262f56;
    background: rgba(189, 204, 212, 0.5);
    box-shadow: 0px 2px 4px rgba(38, 50, 56, 0.08), 0px 4px 6px rgba(38, 50, 56, 0.08);

    &:hover,
    &:active,
    &:focus {
      color: #262f56 !important;
      background: rgba(189, 204, 212, 0.5) !important;
      box-shadow: 0px 2px 4px rgba(38, 50, 56, 0.08), 0px 4px 6px rgba(38, 50, 56, 0.08) !important;
    }

    svg {
      fill: black !important;
      stroke: black !important;
    }
  }

  &--round {
    border-radius: 30px;
  }

  &--semicircular {
    border-radius: 20px;
  }

  &--disabled {
    opacity: 0.5;
  }

  svg {
    width: 16px;
    height: 16px;

    fill: white;
    stroke: white;
  }

  span {
    margin-left: 5px;
  }

  &:hover,
  &:active,
  &:focus {
    background: linear-gradient(270deg, #1a73e9 0%, $color-primary-light 100%);
    background: $color-primary-light;
    box-shadow: 0px 2px 4px rgba(38, 50, 56, 0.08), 0px 4px 6px rgba(38, 50, 56, 0.08);
    color: white;
  }
}
