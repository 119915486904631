.pain_levels {
  &__tooltip {
    position: absolute;
    svg {
      width: 24px;
      height: 24px;
    }

    &_text {
      margin-left: 6px;
      font-size: $fontSize-xs;
      color: rgba(67, 94, 144, 0.82);
      line-height: 16px;
      vertical-align: super;
    }
  }

  img {
    width: 100%;
  }

  &--zoomed {
    overflow-x: auto;
    &__image {
      width: fit-content;
    }
  }
}
