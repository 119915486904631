// Color variables
$color-white: #ffffff;
$color-whiteDark: #f4f4f4;
$color-whiteDarkest: #ededed;
$color-grayLightest: #d1d1d1;
$color-grayLighten: #bdccd4;
$color-grayLight: #adadad;
$color-gray: #969696;
$color-grayDark: #666666;
$color-blackLight: #333333;
$color-black: #1f1f1f;
$color-blackLight: rgba(0, 0, 0, 0.8);

$color-orange: #ff4938;
$color-orangeDark: #f03e2d;
$color-green: #83d14f;
$color-greenLight: #e9f9f6;

$color-red: #be1308;

$color-blue: #0289d6;

$color-layout: #fbfbfb;
$color-shadow: #00000026;
$color-primary: #0847be;
$color-primary-light: #5786ff;
$color-dangerous: #ff7875;
$color-darkBlue: rgba(67, 94, 145, 0.82);
// Screen size variables
$screen-xs: 414px;
$screen-sm-break: 599px;
$screen-sm: 600px;
$screen-md: 1023px;
$screen-lg: 1280px;
$screen-xl-break: 1439px;
$screen-xl: 1440px;
$screen-xxl: 1600px;

// Layouts
$layout-width-base: 1408px;

// Spaces
$spacer: 8px;

$spacer-0: $spacer * 0;
$spacer-1: $spacer / 4;
$spacer-2: $spacer / 2;
$spacer-3: $spacer;
$spacer-4: $spacer * 1.5;
$spacer-5: $spacer * 2;
$spacer-6: $spacer * 2.5;
$spacer-7: $spacer * 3;
$spacer-8: $spacer * 3.5;
$spacer-9: $spacer * 4;
$spacer-10: $spacer * 4.5;
$spacer-11: $spacer * 5;

// Font family variables
$fontFamily-default: 'Source Sans Pro', sans-serif;
$fontFamily-Roboto: 'Roboto', sans-serif;

// Font size variables
$fontSize-xxxs: 12px;
$fontSize-xxs: 14px;
$fontSize-xs: 16px;
$fontSize-sm: 18px;
$fontSize-md: 20px;
$fontSize-lg: 24px;
$fontSize-xlg: 26px;
$fontSize-2xlg: 30px;
$fontSize-xxlg: 32px;

// Font weight
$fontWeight-xxl: 900;

// Modal window
$modalWidth: 350px;

// Base Border
$border-width: 1px;
$border-color: rgba(0, 0, 0, 0.08);
$border-radius: 4px;
$border-radius-content: 8px;

// Antd button height
$button-large: 40px;

// Header
$logo-img-sm: 48px;
$logo-img-xs: 32px;

$logo-max-width-sm: 260px;
$logo-max-width-xs: 170px;

$header-height: 81px;

$transition-default: 0.24s;

$text-color-default: $color-blackLight;
$text-color-gray: $color-gray;

$header-avatar-size: 40px;

$input-xs: 150px;
