.body_areas__page {
  margin: 0 auto;

  &__header {
    font-size: $fontSize-2xlg;
    line-height: 35px;
  }

  &__body {
    @media screen and (max-width: 1367px) {
      margin: auto;
    }

    &__part {
      position: absolute;
    }

    &__chest {
      left: 196px;
      top: 128px;

      &--female {
        top: 120px;
        left: 209px;
      }
    }

    &__head {
      left: 200px;
      top: 12px;

      &--female {
        left: 212px;
        top: 10px;
      }
    }

    &__left__leg {
      left: 262px;
      bottom: 61px;

      &--female {
        left: 270px;
        bottom: 106px;
      }
    }

    &__right__leg {
      left: 193px;
      bottom: 61px;

      &--female {
        left: 205px;
        bottom: 106px;
      }
    }

    &__left__foot {
      left: 289px;
      bottom: 0px;

      &--female {
        bottom: 36px;
        left: 287px;
      }
    }

    &__right__foot {
      left: 206px;
      bottom: 0px;

      &--female {
        bottom: 36px;
        left: 223px;
      }
    }

    &__left_hand {
      left: 429px;
      top: 287px;

      &--female {
        left: 420px;
        top: 274px;
      }
    }

    &__right_hand {
      left: 54px;
      top: 284px;

      &--female {
        left: 71px;
        top: 274px;
      }
    }

    &__left_arm {
      left: 336px;
      top: 130px;

      &--female {
        left: 324px;
        top: 119px;
      }
    }

    &__right_arm {
      left: 82px;
      top: 136px;

      &--female {
        left: 94px;
        top: 122px;
      }
    }
  }

  &__form {
    max-width: 404px;

    @media screen and (max-width: 1367px) {
      width: auto;
      margin-left: 0;
    }
  }
}
