.layout {
  max-width: $screen-xl;
  margin: 0 auto;
  padding: 90px 144px;

  @media (max-width: $screen-sm) {
    padding: 90px 50px 0;
  }

  @media (max-width: 1367px) {
    padding: 5%;
  }
}
