@import '../abstracts/variables';

.ant-layout,
.ant-layout-content,
.ant-layout-footer {
  background-color: $color-white;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0;
}

.ant-form-item-label label {
  font-family: $fontFamily-Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: $fontSize-xxs;
  line-height: 16px;
  color: $color-primary;
}

.ant-input,
.ant-input-affix-wrapper {
  height: 33px;
  border: none;
  border-bottom: 1px solid $color-primary;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: $fontSize-xxs;
  line-height: 16px;

  &:hover,
  &:focus,
  &:active {
    border: none;
    border-bottom: 1px solid $color-primary-light;
    box-shadow: none;
  }

  &-suffix {
    color: rgba(44, 44, 44, 0.6);
  }
}

textarea.ant-input {
  border: 1px solid $color-primary;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $color-primary;
  }
}

.ant-form-item input[type='radio'] {
  width: 22px;
  height: 22px;
}

.ant-radio-group {
  font-family: $fontFamily-Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: $fontSize-xs;
  line-height: 16px;
}

.ant-radio {
  &-wrapper {
    column-gap: 7px;
  }

  &:hover &-inner,
  &-checked::after,
  &-checked &-inner {
    border-color: $color-primary;
  }

  &-inner {
    width: 22px;
    height: 22px;
    border-color: $color-primary;

    &::after {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: $color-primary;
    }
  }

  &:disabled {
    background: linear-gradient(270deg, #1a73e9 0%, $color-primary-light 100%);
    background-color: $color-primary;
    opacity: 0.6;
    color: #ffffff;

    &:hover {
      background: linear-gradient(270deg, #1a73e9 0%, $color-primary-light 100%);
    }

    &-wrapper span {
      color: $color-primary;
    }

    &-checked::after {
      content: none;
    }
  }
}

.ant-radio-input:focus + .ant-radio-inner {
  border-color: $color-primary;
}

.ant-checkbox-group {
  font-family: $fontFamily-Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: $fontSize-xs;
  line-height: 16px;
  color: $color-blackLight;
}

.ant-form-item {
  &-required {
    align-items: normal !important;
  }
}

.ant-form-item input[type='checkbox'] {
  width: 20px;
  height: 20px;
}

.ant-checkbox {
  font-size: 22px;

  &-wrapper + &-wrapper {
    margin: 0;
  }

  &-inner {
    width: 22px;
    height: 22px;
    border-color: $color-primary;
  }

  &-checked::after,
  &-wrapper:hover &-inner,
  &-input:focus + &-inner {
    border-color: $color-primary;
  }

  &-checked &-inner {
    background-color: $color-white;
    border-color: $color-primary;
  }

  &-checked &-inner::after {
    width: 9px;
    height: 13px;
    border-top: 0 !important;
    border-left: 0 !important;
    border: 2px solid $color-primary;
  }
}

.ant-btn {
  padding: 0 15px;
  height: 48px;
  border-radius: 5px;
  font-family: $fontFamily-Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: $fontSize-xs;
  line-height: 16px;
  text-transform: uppercase;

  &-primary {
    border-color: $color-primary;
    background: $color-primary;

    &:hover,
    &:focus {
      border-color: $color-primary-light;
      background: $color-primary-light;
    }
  }
}

.ant-spin {
  &-blur {
    opacity: 1;
  }

  &-dot-item {
    background-color: $color-primary;
  }
}

.ant-input-affix-wrapper {
  &:focus,
  &-focused {
    box-shadow: none;
  }

  input {
    height: 26px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.ant-select {
  &:not(&-customize-input) &-selector {
    border: none;
    border-bottom: 1px solid $color-primary;
  }

  &-focused:not(&-disabled).ant-select:not(&-customize-input) &-selector {
    box-shadow: none;
  }

  &-arrow .anticon > svg {
    border: 6px solid transparent;
    border-top: 11px solid $color-primary;
  }
}

.ant-picker {
  width: 100%;
  border: none;
  border-bottom: 1px solid $color-primary;

  &:hover,
  &-focused {
    border: none;
    border-bottom: 1px solid $color-primary;
  }

  &-focused {
    box-shadow: none;
  }

  &-range {
    &-wrapper {
      position: absolute;
    }
  }

  &-time-panel-column {
    overflow-y: scroll;
  }
}

.ant-modal {
  &-content {
    padding: 10px;
  }

  &-header {
    padding-bottom: 0;
    border: none;
  }

  &-body {
    padding-bottom: 0;
    textarea {
      background: #ffffff;
      border: 1px solid #bdccd4;
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(189, 204, 212, 0.5);
      border-radius: 10px;

      &::placeholder {
        color: rgba(44, 44, 44, 0.71);
      }
    }
  }

  &-footer {
    padding-top: 0;
    padding-right: 23px;
    border: none;
  }

  &-title {
    font-size: $fontSize-2xlg;
  }
}

.ant-table {
  &-row {
    &--opaque {
      background-color: $color-whiteDark;
    }
  }
}
