.treatment-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 24px 24px 0;
  background-color: #171c24;
  color: #f5f7fa;

  & .wrapper {
    width: 100%;
    max-width: 375px;
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  & .main-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
  }

  & .title__container {
    padding: 48px 0 40px;
    text-align: center;
  }

  & .title__text {
    font-size: 24px;
    font-weight: 500;
    line-height: 33.6px;
    color: #f5f7fa;
  }

  & .content-card__container {
    background-color: #1f242e;
    border-radius: 16px;
    padding: 16px;
    text-align: center;
  }

  & .content-card__text {
    font-size: 16px;
    line-height: 22.4px;
    color: #b8c1cc;
  }

  & .navigation-button-wrapper {
    width: 100%;
    position: sticky;
    bottom: 0px;
    padding: 20px 0;
    background-color: #171c24;
  }

  & .navigation-button {
    width: 100%;
    background-color: #1f8bfd;
    border-color: #1f8bfd;
    border-radius: 16px;
    color: #f5f7fa;
  }

  & .form__description {
    font-size: 16px;
    line-height: 22.4px;
    color: #b8c1cc;
    text-align: center;
    margin-bottom: 16px;
  }

  & .form__block__label {
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
    text-align: center;
    margin-bottom: 16px;
  }

  & .pain-level-selector__wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 16px 24px;
    border-radius: 40px;
    background-color: #1f242e;

    &:hover {
      cursor: pointer;
    }
  }

  & .form__selector-switcher {
    width: fit-content;
    margin: 0 auto;
    border-bottom: 1px dotted #f5f7fa;
    cursor: pointer;
  }
}

.ant-modal-wrap {
  top: unset;
}
// Attention!
.pain-level-selector__modal {
  border-radius: 16px;
  max-width: 375px;
  top: 0;
  margin: 0 auto;
  padding: 0;

  & .ant-modal-content {
    padding: 24px 24px 0;
    border-radius: 16px;
    background-color: #29303d;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    max-height: 100vh;

    & .ant-modal-close {
      color: #f5f7fa;
    }

    & .ant-modal-header {
      padding: 0;
      background-color: #29303d;
    }

    & .ant-modal-title {
      font-size: 24px;
      color: #f5f7fa;
    }

    & .ant-modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      overflow: scroll;
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    & .navigation-button-wrapper {
      width: 100%;
      position: sticky;
      bottom: 0px;
      padding: 20px 0;
      background-color: #29303d;
    }

    & .navigation-button {
      width: 100%;
      background-color: #1f8bfd;
      border-color: #1f8bfd;
      border-radius: 16px;
      color: #f5f7fa;
    }
  }

  & .pain-level-selector__radio-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .ant-radio {
      display: none;
    }

    & .ant-radio-wrapper {
      width: 45%;
      height: 64px;
      justify-content: center;
      align-items: center;
      column-gap: 0px;
      border-radius: 40px;
      margin-right: 0;
      margin-bottom: 16px;
      color: #f5f7fa;
      font-size: 24px;
      font-weight: 600;
    }

    & .ant-radio-wrapper-checked {
      border: 8px solid white;
    }
  }
}
