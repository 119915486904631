.pain_areas__page {
  &__header {
    font-size: $fontSize-2xlg;
    line-height: 35px;

    margin-bottom: 40px;
    margin-left: 12px;
  }

  &__areas_list {
    width: 100%;
    max-width: 576px;
  }

  &__footer {
    margin-left: 12px;

    @media (max-width: $screen-xl-break) {
      justify-content: center;
    }
  }

  &__nav_controls {
    width: 550px;
  }

  .warning {
    margin-left: 82px;
    font-size: $fontSize-xxs;
    line-height: 18px;

    color: #c30000;

    @media (max-width: $screen-xl-break) {
      width: 100%;
      text-align: center;
      margin-left: 0;
    }
  }
}
