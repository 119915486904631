.thanks-page {
  &__container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    margin: 14px 0;
    text-align: center;
  }

  &__description {
    max-width: 742px;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 140%;
    text-align: center;
  }

  &__btn {
    margin-top: 56px;
    padding: 0 29px;
  }

  &__tip {
    font-size: $fontSize-sm;
    line-height: 21px;
    color: rgba(44, 44, 44, 0.6);
    margin-top: 30px;
    margin-bottom: 60px;
  }

  &__approved_text {
    margin-top: 60px;
    font-size: $fontSize-sm;
    line-height: 140%;
    color: #2c2c2c;
  }
}
