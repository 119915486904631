.diary_medications {
  &__checkbox {
    span {
      align-self: flex-start;
      line-height: 16px;
    }
  }

  &__add_button {
    margin-bottom: 50px !important;
  }

  &__back_button {
    span {
      vertical-align: text-top;
    }
  }

  &_item {
    &__title {
      font-size: $fontSize-lg;
      line-height: 28px;
      color: #2c2c2c;
      display: block;
      margin-bottom: 24px;
    }

    &__remove {
      path {
        fill: rgb(8, 71, 190) !important;
      }
    }
  }

  &__modal {
    .ant-modal-body {
      margin-bottom: 20px;
    }
  }

  button {
    width: 100%;
    margin-bottom: 24px;

    span {
      margin-left: 10px;
    }

    box-shadow: 0px 2px 4px rgba(38, 50, 56, 0.08), 0px 4px 6px rgba(38, 50, 56, 0.08);
    border: 2px solid #dfe9fc;
  }
}
