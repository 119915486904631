@import '../abstracts/variables';

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal
}

#root {
  height: 100vh;
  background-color: $color-white;
}
