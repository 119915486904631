.popup {
  margin-left: 44px;
  width: 52%;
  cursor: pointer;

  @media (max-width: $screen-md) {
    width: 100%;
  }

  &__img {
    width: 100%;
  }
}
