.badge {
  text-align: center;
  padding: 8px 20px;

  background-color: $color-grayLighten;
  opacity: 0.5;
  border-radius: 20px;

  text-transform: uppercase;
  color: $color-darkBlue;

  font-size: $fontSize-xs;
  line-height: 19px;
}
