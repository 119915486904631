.canvas__controls {
  width: 550px;
  padding: 0 20px;
  position: absolute;
  top: 21px;
  z-index: 10;
}

.genitals__toggler {
  position: absolute;
  bottom: 60px;
  left: 380px;
  z-index: 10;

  button {
    background: #5a6f98;

    &:hover,
    &:active,
    &:focus {
      background: #5a6f98;
    }
  }
}

.sketch_field {
  position: absolute;
  &--disabled {
    pointer-events: none;
  }
}

canvas,
.lower-canvas,
.upper-canvas {
  z-index: 10;
}
