@import '../abstracts/variables';

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;

  &__row {
    text-align: center;
    margin-bottom: 4px;
  }
}

@media (max-width: ($screen-md - 1)) {
  .flex {
    & > * {
      margin-right: 0;
      margin-left: 0;
    }

    &__row {
      & > * {
        margin-bottom: $spacer * 3;
      }
      .ant-col {
        & > * {
          margin-right: 0 !important;
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media (min-width: $screen-md) {
  .flex {
    text-align: initial;
    flex-direction: row;
    align-items: flex-start;

    &--align-center {
      align-items: center;
    }
  }
}

.flex-noWrap {
  flex-wrap: nowrap;
}
