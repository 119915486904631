.dts {
  &__treatment {
    &_item {
      &__title {
        font-size: $fontSize-lg;
        line-height: 28px;

        color: #2c2c2c;
      }

      &__remove {
        path {
          fill: rgb(8, 71, 190) !important;
        }
      }
    }

    &_summary {
      &__modal {
        padding: 0;
        margin: 0;

        &__footer {
          button {
            display: block;
            width: 100%;

            margin-left: 0 !important;
            margin-bottom: 20px;

            box-shadow: 0px 2px 4px rgba(38, 50, 56, 0.08), 0px 4px 6px rgba(38, 50, 56, 0.08);
            border: 2px solid #dfe9fc;
          }
        }

        &__values {
          margin-top: 20px;

          &__pain_level {
            width: 100%;

            .ant-form-item {
              width: 45%;
            }
          }

          &__time_range {
            border: none;
            padding: 4px 0;

            justify-content: space-between;

            .ant-picker {
              &-input {
                width: 45%;
                padding: 4px 11px;
                border-bottom: 1px solid #0847be;
              }

              &-range-separator {
                display: none;
              }
            }
          }
          .ant-picker:hover,
          .ant-picker-focused {
            border: none;
          }

          .ant-picker-active-bar {
            display: none;
          }
        }
      }
    }
  }
  button {
    width: 100%;
    margin-bottom: 24px;
    margin-top: 24px;

    box-shadow: 0px 2px 4px rgba(38, 50, 56, 0.08), 0px 4px 6px rgba(38, 50, 56, 0.08);
    border: 2px solid #dfe9fc;
  }
}
